.seperator {
    height: 24px;
    width: 76px;
}

.seperator .overlap-group {
    height: 24px;
    position: relative;
}

.seperator .rectangle {
    background-color: #a1e5e6;
    border-radius: 9px;
    height: 18px;
    left: 10px;
    position: absolute;
    top: 0;
    width: 66px;
}

.seperator .div {
    border: 2px solid;
    border-color: var(--cta);
    border-radius: 9px;
    height: 18px;
    left: 0;
    position: absolute;
    top: 6px;
    width: 66px;
}
