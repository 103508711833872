@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,600&display=swap");

body {
  font-family: "Nunito", sans-serif;
}
:root {
  --almost-white: rgba(253, 253, 253, 1);
  --another-blue: rgba(225, 236, 255, 1);
  --background: #f9f9f9;
  --cta: rgba(0, 162, 173, 1);
  --dark-red: rgba(118, 7, 0, 1);
  --drop-shadow-1: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  --frosted-glass: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  --kuning-highlight: rgba(255, 153, 0, 1);
  --navbar-accent: rgba(105, 123, 161, 1);
  --primary-text: rgba(51, 51, 51, 1);
  --secondary-background: rgba(239, 245, 255, 1);
  --secondary-text: #505050;
  --shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
  --white: rgba(255, 255, 255, 1);

  --font-family-reg: "Nunito", Helvetica;
  --font-family-bold: "Nunito", Helvetica;

  --overline-font-size: 10px;
  --caption-font-size: 14px;
  --body-font-size: 18px;
  --subtitle-font-size: 18px;
  --title-font-size: 46px;

  --header-1-font-size: 64px;
  --header-2-font-size: 56px;
  --header-3-font-size: 24px;
}

.text-overline {
  font-family: var(--font-family-reg);
  font-size: var(--overline-font-size);
}

.text-overline-bold {
  font-family: var(--font-family-bold);
  font-size: var(--overline-font-size);
  font-weight: 600;
}

.text-caption {
  font-family: var(--font-family-reg);
  font-size: var(--caption-font-size);
}

.text-caption-bold {
  font-family: var(--font-family-bold);
  font-size: var(--caption-font-size);
  font-weight: 600;
}

.text-body {
  font-family: var(--font-family-reg);
  font-size: var(--body-font-size);
}

.text-body-bold {
  font-family: var(--font-family-bold);
  font-size: var(--body-font-size);
  font-weight: 700;
}

.text-subtitle {
  font-family: var(--font-family-reg);
  font-size: var(--subtitle-font-size);
}

.text-subtitle-bold {
  font-family: var(--font-family-bold);
  font-size: var(--subtitle-font-size);
  font-weight: 700;
}

.text-title {
  font-family: var(--font-family-reg);
  font-size: var(--title-font-size);
}

.text-title-bold {
  font-family: var(--font-family-bold);
  font-size: var(--title-font-size);
  font-weight: 700;
}

.text-header-1 {
  font-family: var(--font-family-bold);
  font-size: var(--header-1-font-size);
  font-weight: 600;
}

.text-header-2 {
  font-family: var(--font-family-bold);
  font-size: var(--header-2-font-size);
  font-weight: 600;
}

.text-header-3 {
  font-family: var(--font-family-bold);
  font-size: var(--header-3-font-size);
  font-weight: 600;
}

.cta-button {
  align-items: center;
  background: var(--cta);
  border-radius: 26px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 14px 32px;
}

.sec-button {
  align-items: center;
  border-radius: 26px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 12px 32px;
  font-weight: 700;
  color: var(--cta);
}

/* .embla__slide {
  flex: 0 0 auto;
  height: 100%; 
} */
#landmark.embla {
  --slide-spacing: 2.5rem;
  --slide-size: 100%;
  /* background-color: pink; */
}
#landmark.embla .embla__viewport {
  overflow: hidden;
  height: fit-content;
  /* background-color: pink; */
}
#landmark.embla .embla__container {
  display: flex;
  margin-left: calc(var(--slide-spacing) * -1);
  backface-visibility: hidden;
  touch-action: pan-y;
  /* background-color: pink; */
}
#landmark.embla .embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  /* padding-left: var(--slide-spacing); */
  position: relative;
  /* height: 81vh; */
  aspect-ratio: 16/9;
  width: 100vw;
}

#landmark.embla .embla__slide2 {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
  height: 81vh;
  aspect-ratio: 16/9;
  width: 100vw;
}

#landmark.embla__pills {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  height: 530px;
}

#konseling.embla__pills {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  height: fit-content;
  margin-top: 10px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px; */
}

.embla {
  overflow: hidden;
  color: papayawhip;
  margin-left: 30px;
  margin-right: 30px;
  height: auto;
}

.embla__container--vertical {
  flex-direction: column;
  height: 530px;
  margin-left: 10px;
}

.embla__container {
  display: flex;
}

.accordion_wrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 300ms;
}

.accordion_expandable {
  min-height: 0;
}

.accordion_wrapper.open {
  grid-template-rows: 1fr;
}

#slider.embla {
  --slide-spacing: 2.5rem;
  --slide-size: 100%;
}

#slider.embla .embla__viewport {
  overflow: hidden;
}

#slider.embla .embla__container {
  display: flex;
  margin-left: calc(var(--slide-spacing) * -1);
  backface-visibility: hidden;
  touch-action: pan-y;
}

#slider.embla .embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
  /* height: 81vh; */
  aspect-ratio: 16/9;
  width: 100vw;
}

#slider.embla__pills {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

::-webkit-scrollbar {
  margin-left: 10px;
  padding-left: 10px;
  width: 8px; /* Width of the scrollbar */
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 8px; /* Border radius of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* Hide scrollbar for Chrome, Safari, and Edge */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media screen and (min-width: 570px) {
  .embla__container--vertical {
    height: 460px;
  }

  .embla {
    height: 300px;
  }

  #slider.embla .embla__slide {
    height: 81vh;
  }
}

@media screen and (min-width: 900px) {
  .embla__container--vertical {
    height: 530px;
  }

  #slider.embla {
    --slide-spacing: 1rem;
    --slide-size: 75%;
  }
}

.no-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  scroll-behavior: smooth;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}