.navbar {
  background-color: #ffffff;
  border-radius: 44px;
}

.navbar .toggle {
  background-color: var(--secondary-text);
  border-radius: 42px;
  box-shadow: var(--shadow);
  height: fit-content;
}

.navbar .overlap-group {
  border-radius: 17.33px;
  height: 35px;
  width: 35px;
}

.navbar .flag-for-flag {
  height: 21px;
  width: 21px;
}

.navbar .flag-for-flag-united {
  height: 21px;
  width: 21px;
}

.navbar .div {
  align-items: center;
  display: inline-flex;
  gap: 72px;
}

.navbar .text-wrapper {
  color: var(--cta);
  font-family: "Montserrat-SemiBold", Helvetica, serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.navbar .text-wrapper-2 {
  color: var(--secondary-text);
  font-family: var(--body-18pt-font-family);
  font-size: var(--body-18pt-font-size);
  font-style: var(--body-18pt-font-style);
  font-weight: 500;
  letter-spacing: var(--body-18pt-letter-spacing);
  line-height: var(--body-18pt-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.navbar .line {
  height: 6px;
  width: 26px;
}

.navbar .artboard-copy {
  /* height: 72px; */
  object-fit: cover;
}
