/* styles.css */
.transition-container {
  position: relative;
  height: 100px;
  width: 100%;
  overflow: hidden;
}

.transition-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.9s ease-in-out;
}

.transition-item.active {
  opacity: 1;
}
